import '@fontsource/montserrat';
import '@fontsource/montserrat/600.css';
import '@fontsource/roboto-mono';
import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import '../index.css';
import { Helmet } from 'react-helmet';

const Grid = styled.div`
    margin: 0 0 20px 0;
    display: grid;
    grid-gap: 44px;
    grid-template-columns: repeat(6, 1fr);
    grid-auto-flow: dense;

    @media only screen and (max-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 16px;
    }
`;

interface GridItemInterface {
    featured?: boolean;
}
const GridItem = styled.div<GridItemInterface>`
    color: #fff;
    border-radius: 5px;
    font-size: 150%;
    aspect-ratio: 1;

    ${(props) =>
        props.featured &&
        css`
            grid-column-start: auto;
            grid-column-end: span 2;
            grid-row-start: auto;
            grid-row-end: span 2;
        `}
`;

const featured = [
    'L3',
    'b3',
    'c1',
    'f1',
    'i2',
    'k1',
    'h3',
    'n1',
    'o3',
    'q1',
    's2',
    't1',
];

const links: { [key: string]: string } = {
    f2: 'https://www.youtube.com/watch?v=XzawM0nbPIo',
    f3: 'https://www.youtube.com/watch?v=ryBJS71Jdf8',
};

const IndexPage = ({ data }: { data: any }) => {
    return (
        <>
            <Helmet title="Home | Greative" defer={false}>
                <meta
                    name="description"
                    content="Brands that work in the attention economy."
                />
            </Helmet>
            <Grid>
                {data.allFile.nodes.map((n: any, i: number) => {
                    const image = getImage(n);
                    if (!image) {
                        return;
                    }
                    return (
                        <GridItem featured={featured.includes(n.name)} key={i}>
                            {/* <S>{n.name}</S> */}
                            {(!!links[n.name] && (
                                <a
                                    href={links[n.name]}
                                    target="_blank"
                                    rel="noreferrer"
                                    aria-label="Youtube"
                                >
                                    <GatsbyImage
                                        className="grid-item"
                                        alt=""
                                        image={image}
                                    />
                                </a>
                            )) || (
                                <GatsbyImage
                                    className="grid-item"
                                    alt=""
                                    image={image}
                                />
                            )}
                        </GridItem>
                    );
                })}
            </Grid>
        </>
    );
};

export default IndexPage;

export const query = graphql`
    query {
        allFile(
            filter: { relativeDirectory: { eq: "projects" } }
            sort: { fields: name, order: ASC }
        ) {
            nodes {
                childImageSharp {
                    gatsbyImageData(width: 460)
                }
                name
            }
        }
    }
`;
